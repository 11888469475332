import { useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { useSelector } from "react-redux";
import BackButton from "../../components/BackButton";
import Header from "../../components/Header";
import SpinnerLoader from "../../components/SpinnerLoader";
import TabNavbar from "../../components/Tabbar";
import ErrorModal from "../../components/modal/ErrorModal";
import SaveConfirmModal from "../../components/modal/SaveConfirmModal";
import {
  SessionBranchItem,
  SessionGroupItem,
} from "../../interfaces/allocation";
import { SessionStatus } from "../../interfaces/sessions";
import {
  getCourseSessionBranches,
  getCourseSessionGroups,
  setSelectedCourseSessionData,
  updateSessionSeatAllocation,
} from "../../redux/slices/allocation.slice";
import { updateSessionById } from "../../redux/slices/session.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import { ADMIN_TABS } from "../../utils/TabbarData";

function SeatAllocation() {
  const [selectedType, setSelectedType] = useState("branch");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [modalMessage, setModalMessage] = useState("");
  const [sessionStatus, setSessionStatus] = useState<SessionStatus | null>(
    null
  );
  const [minMaxEnroll, setMinMaxEnroll] = useState({
    min: 1,
    max: 1,
  });
  const [sessionBranches, setSessionBranches] = useState<SessionBranchItem[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [sessionGroups, setSessionGroups] = useState<SessionGroupItem[]>([]);

  const dispatch = useAppDispatch();

  const selectedTypeClassName = "bg-white text-[#961e38]";

  const {
    branches,
    groups,
    branchLoading,
    groupLoading,
    selectedSessionData,
    saveLoading,
  } = useSelector((state: RootState) => state.allocation);

  const isEditableStatus = (status: SessionStatus | null) =>
    ["ALLOCATION", "APPLICATION", "EXCLUDED_AT_ALLOC"].includes(status || "");

  const filteredBranches = sessionBranches.filter((branch) =>
    branch.branch_title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredGroups = sessionGroups.filter((group) =>
    group.group_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const validateSeatAllocation = () => {
    const totalBranchSeats = sessionBranches.reduce(
      (sum, branch) => sum + branch.seat_allocated,
      0
    );
    const totalGroupSeats = sessionGroups.reduce(
      (sum, group) => sum + group.seat_allocated,
      0
    );

    const totalSeats = totalBranchSeats + totalGroupSeats;

    if (totalSeats !== minMaxEnroll.max) {
      setModalMessage(
        `Total allocated seats (${totalSeats}) do not match the maximum allotment (${minMaxEnroll.max}).`
      );
      setIsErrorModalOpen(true);
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (sessionStatus === "AVAILABLE") {
      setSessionBranches([]);
      setSessionGroups([]);
    }
  }, [sessionStatus]);

  const handleMinMaxSeatsSave = async () => {
    setUpdateLoading(true);
    try {
      if (minMaxEnroll.min > minMaxEnroll.max || minMaxEnroll.min < 0) {
        setUpdateLoading(false);
        setErrorMessage("Minimum seats cannot be greater than maximum seats");
        return;
        // throw new Error("Minimum seats cannot be greater than maximum seats");
      }
      setErrorMessage(null);
      const res = await dispatch(
        updateSessionById({
          max_enrollments: minMaxEnroll.max,
          min_enrollments: minMaxEnroll.min,
          session_id: selectedSessionData.session_id,
        })
      );

      console.log("res.payload.data====", res.payload.data);

      if (res.payload) {
        console.log("res.payload.data====", res.payload.data);
        dispatch(setSelectedCourseSessionData(res.payload.data));
        setSessionStatus(res.payload.data.session_status);
        setMinMaxEnroll({
          min: res.payload.data.min_enroll,
          max: res.payload.data.max_enroll,
        });
      }

      setUpdateLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const updateAllocationData = (
    selectedType: string,
    id: number,
    value: number
  ) => {
    if (selectedType === "branch") {
      setSessionBranches((prev) =>
        prev.map((branch) =>
          branch.branch_id === id
            ? { ...branch, seat_allocated: value }
            : branch
        )
      );
    } else if (selectedType === "group") {
      setSessionGroups((prev) =>
        prev.map((group) =>
          group.group_id === id ? { ...group, seat_allocated: value } : group
        )
      );
    }
  };

  const getStateBySelectType = (selectedType: string) =>
    selectedType === "branch" ? filteredBranches : filteredGroups;

  const renderSeatTitle = (seat: SessionBranchItem | SessionGroupItem) =>
    "branch_title" in seat ? seat.branch_title : seat.group_name;

  const handleMinChange = (value: number) => {
    // if (value >= 0 && value <= minMaxEnroll.max) {
    setMinMaxEnroll((prev) => ({ ...prev, min: value }));
    // }
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const closeSaveModal = () => {
    setIsSaveModalOpen(false);
  };

  const handleMaxChange = (value: number) => {
    // if (value >= minMaxEnroll.min) {
    setMinMaxEnroll((prev) => ({ ...prev, max: value }));
    // }
  };

  const handleSave = () => {
    if (validateSeatAllocation() && sessionStatus === "ALLOCATION") {
      saveUpdatedSeatAllocation();
    } else {
      setIsSaveModalOpen(false);
    }
  };

  const saveUpdatedSeatAllocation = async () => {
    let bodyPayload = {
      min_enrollments: minMaxEnroll.min,
      max_enrollments: minMaxEnroll.max,
      session_name: selectedSessionData.session_name,
      seat_allocation_branches: sessionBranches.map((branch) => ({
        branch_id: branch.branch_id,
        seat_allocated: branch.seat_allocated,
      })),
      seat_allocation_groups: sessionGroups.map((group) => ({
        group_id: group.group_id,
        seat_allocated: group.seat_allocated,
      })),
    };

    await dispatch(
      updateSessionSeatAllocation({
        session_id: selectedSessionData.session_id,
        payload: bodyPayload,
      })
    );

    setIsSaveModalOpen(false);
  };

  useEffect(() => {
    if (selectedSessionData) {
      setSessionStatus(selectedSessionData.session_status);
      setMinMaxEnroll({
        min: selectedSessionData.min_enroll,
        max: selectedSessionData.max_enroll,
      });
    }
  }, [selectedSessionData]);

  useEffect(() => {
    if (branches.length > 0) {
      setSessionBranches(branches);
    }

    if (groups.length > 0) {
      setSessionGroups(groups);
    }
  }, [branches, groups]);

  useEffect(() => {
    if (isEditableStatus(selectedSessionData.session_status)) {
      dispatch(
        getCourseSessionBranches({ session_id: selectedSessionData.session_id })
      );
      dispatch(
        getCourseSessionGroups({ session_id: selectedSessionData.session_id })
      );
    }
  }, [dispatch, selectedSessionData]);

  const data = getStateBySelectType(selectedType);

  return (
    <div className="flex flex-col items-start gap-8">
      <BackButton />
      <Header />
      <TabNavbar
        id="seat-allocation"
        handleSaveAndPublish={() =>
          sessionStatus === "ALLOCATION" && setIsSaveModalOpen(true)
        }
        isLoading={saveLoading}
        isDisable={sessionStatus !== "ALLOCATION"}
        tabs={ADMIN_TABS}
        selectedTabId={1}
        doesShow={sessionStatus !== "AVAILABLE"}
      />
      <div className="w-full h-full">
        <table className="w-full border-collapse">
          <thead className="bg-[#961e38] w-full text-white rounded-t-xl">
            <tr>
              <th colSpan={2} className="py-2 text-left rounded-t-xl">
                <div className="px-3 py-2 flex items-center gap-2 w-full">
                  <GoSearch size={18} />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    disabled={branchLoading && groupLoading}
                    className="w-full p-2 border-b-2 border-white bg-inherit focus:border-white focus:outline-none"
                  />
                </div>
              </th>
            </tr>
            <tr>
              <th className="py-2 px-4 text-left">
                <div className="flex items-center gap-3">
                  <button
                    className={`py-1.5 px-10 cursor-pointer font-semibold ${
                      selectedType === "branch"
                        ? selectedTypeClassName
                        : "bg-[#961e38] text-white"
                    }`}
                    disabled={branchLoading && groupLoading}
                    onClick={() => setSelectedType("branch")}
                  >
                    Branch
                  </button>
                  <button
                    className={`py-1.5 px-10 cursor-pointer font-semibold ${
                      selectedType === "group"
                        ? selectedTypeClassName
                        : "bg-[#961e38] text-white"
                    }`}
                    disabled={branchLoading && groupLoading}
                    onClick={() => setSelectedType("group")}
                  >
                    Group
                  </button>
                </div>
              </th>
              <th className="py-2 px-4 text-right">Number of Seats</th>
            </tr>
          </thead>
          <tbody>
            {branchLoading && groupLoading ? (
              <tr>
                <td
                  colSpan={2}
                  className="flex items-center justify-center py-5"
                >
                  <SpinnerLoader />
                </td>
              </tr>
            ) : (
              <>
                {data.length > 0 ? (
                  data.map((seat: any, i) => (
                    <tr key={i}>
                      <td className="border-b border-[#c6c6c6] py-2 px-4 text-left">
                        {renderSeatTitle(seat)}
                      </td>
                      <td className="border-b border-[#c6c6c6] py-2 px-4 text-right">
                        <input
                          className="w-[50px] border border-[#909090] text-center"
                          value={seat.seat_allocated}
                          disabled={
                            sessionStatus === "APPLICATION" ||
                            sessionStatus === "EXCLUDED_AT_ALLOC"
                          }
                          onChange={(e) =>
                            updateAllocationData(
                              selectedType,
                              seat.branch_id || seat.group_id,
                              Number(e.target.value)
                            )
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={2}>
                      <p className="w-full text-center py-5">
                        No Data Available, please add min and max seats
                      </p>
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          <tfoot className="bg-[#961e38]">
            <tr>
              <td colSpan={2} className="p-5 text-white rounded-b-xl">
                <div className="flex items-center justify-between rounded-b-xl">
                  <div className="flex items-center gap-2">
                    <p className="text-sm">Min Seats</p>
                    <input
                      className="w-12 text-center bg-white text-black"
                      value={minMaxEnroll.min}
                      onChange={(e) => handleMinChange(Number(e.target.value))}
                      disabled={sessionStatus !== "AVAILABLE"}
                    />
                    <p className="text-sm">Max Seats</p>
                    <input
                      className="w-12 text-center bg-white text-black"
                      value={minMaxEnroll.max}
                      onChange={(e) => handleMaxChange(Number(e.target.value))}
                      disabled={sessionStatus !== "AVAILABLE"}
                    />
                  </div>
                  {sessionStatus === "AVAILABLE" && (
                    <button
                      className="py-1.5 px-5 border border-white"
                      onClick={handleMinMaxSeatsSave}
                      disabled={updateLoading}
                    >
                      {updateLoading ? "Saving..." : "Save"}
                    </button>
                  )}
                </div>
                {errorMessage && (
                  <div>
                    <p>{errorMessage}</p>
                  </div>
                )}
              </td>
            </tr>
          </tfoot>
        </table>
        {isErrorModalOpen && (
          <ErrorModal
            closeModal={closeErrorModal}
            modalMessage={modalMessage}
          />
        )}
        {isSaveModalOpen && (
          <SaveConfirmModal
            closeModal={closeSaveModal}
            handleSave={handleSave}
            loading={saveLoading}
            msg="Are you sure you want to save current seat allocation for this
            session?"
          />
        )}
      </div>
    </div>
  );
}

export default SeatAllocation;
