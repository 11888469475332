import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CourseCard from "../../../components/Card/CourseCard";
import Header from "../../../components/Header";
import HomeButton from "../../../components/HomeButton";
import Pagination from "../../../components/Pagination";
import SearchWithFilter from "../../../components/SearchWithFilter";
import SpinnerLoader from "../../../components/SpinnerLoader";
import TabNavbar from "../../../components/Tabbar";
import {
  getLearnerAvailableApplications,
  setSelectedCourse,
} from "../../../redux/slices/learner.applications.slice";
import { RootState, useAppDispatch } from "../../../redux/store";
import { LEARNER_TABS } from "../../../utils/TabbarData";

function AvailableTrainings() {
  const dispatch = useAppDispatch();

  const { availableCourses, loading } = useSelector(
    (state: RootState) => state.learnerApplications
  );

  const [searchTerm, setSearchTerm] = useState("");

  const [currentPage, setCurrentPage] = useState(1); // Current page state

  const [itemsPerPage, setItemsPerPage] = useState(4);

  const [filteredCourses, setFilteredCourses] =
    useState<any[]>(availableCourses);
  const totalPages =
    filteredCourses.length > 0
      ? Math.ceil(filteredCourses?.length / itemsPerPage)
      : 1;
  const navigate = useNavigate();

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
    const filteredData = availableCourses.filter((course: any) => {
      return course.course_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCourses(filteredData);
  };

  const handleNavigateToCourseSessions = (id: number | string) => {
    const courseById = availableCourses.find(
      (course: any) => course.course_id === id
    );

    dispatch(setSelectedCourse(courseById));

    navigate("/learner/available-trainings/" + id);
  };

  useEffect(() => {
    dispatch(getLearnerAvailableApplications());
  }, [dispatch]);

  // Derive paginated data dynamically
  const paginatedData = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setFilteredCourses(availableCourses);
  }, [availableCourses]);

  return (
    <div className="flex flex-col items-start gap-8">
      <HomeButton />
      <Header />
      <TabNavbar id="" tabs={LEARNER_TABS} selectedTabId={1} />
      {/* Search and Filter */}
      <div className="w-full">
        <SearchWithFilter
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          disabled={loading}
        />
      </div>
      {loading ? (
        <div className="flex items-center w-full justify-center">
          <SpinnerLoader />
        </div>
      ) : (
        <div className="flex flex-col w-full justify-start items-start gap-8 px-10">
          {paginatedData.map((item: any) => (
            <>
              <CourseCard
                handleNavigateToCourseSessions={handleNavigateToCourseSessions}
                item={item}
              />
            </>
          ))}
          <Pagination
            totalPages={totalPages}
            total_item_count={filteredCourses.length}
            handleChange={({ page, itemsPerPage }) => {
              setCurrentPage(page);
              setItemsPerPage(itemsPerPage);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default AvailableTrainings;
