import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import HomeButton from "../../../components/HomeButton";
import SpinnerLoader from "../../../components/SpinnerLoader";
import TabNavbar from "../../../components/Tabbar";
import {
  getLearnerAppliedApplications,
  getLearnerAvailableApplications,
  setSelectedCourse,
  updateLearnerApplicationStatus,
} from "../../../redux/slices/learner.applications.slice";
import { RootState, useAppDispatch } from "../../../redux/store";
import { LEARNER_TABS } from "../../../utils/TabbarData";
import { formatString } from "../../../utils/helper";
import Pagination from "../../../components/Pagination";
import SearchWithFilter from "../../../components/SearchWithFilter";
import SaveConfirmModal from "../../../components/modal/SaveConfirmModal";
const STATUS_MAP: any = {
  APPLIED: {
    label: "Manager Approval Pending",
    color: "bg-orange-500 text-white",
  },
  APPROVED_BY_MANAGER: {
    label: "Finding a Seat",
    color: "bg-blue-500 text-white",
  },
  WAITING: { label: "Waiting List", color: "bg-gray-500 text-white" },
  CONFIRMED_BY_LEARNER: {
    label: "Seat Confirmed",
    color: "bg-green-500 text-white",
  },
  IN_PROGRESS: {
    label: "Session in Progress",
    color: "bg-purple-500 text-white",
  },
  REJECTED_BY_MANAGER: {
    label: "Denied by manager",
    color: "bg-red-500 text-white",
  },
  REJECTED_BY_LEARNER: {
    label: "Denied by learner",
    color: "bg-red-500 text-white",
  },
  ENDED: { label: "Session Ended", color: "bg-gray-500 text-white" },
  REJECTED_BY_TOA: {
    label: "Denied by system",
    color: "bg-red-500 text-white",
  }
};

function ApplicationStatus() {
  const [currentPage, setCurrentPage] = useState(1); // Current page state

  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>("");
  const [currentCourseId, setCurrentCourseId] = useState<number>(0);

  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useAppDispatch();

  const { appliedCourses, loading, updateLoading } = useSelector(
    (state: RootState) => state.learnerApplications
  );
  const [filteredCourses, setFilteredCourses] = useState<any[]>(appliedCourses);
  const totalPages =
    filteredCourses.length > 0
      ? Math.ceil(filteredCourses?.length / itemsPerPage)
      : 1;
  const paginatedData = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    dispatch(getLearnerAppliedApplications());
  }, [dispatch]);

  useEffect(() => {
    setFilteredCourses(appliedCourses);
  }, [appliedCourses]);

  const handleApply = async () => {
    if (
      currentCourseId === 0 ||
      (currentStatus !== "CONFIRMED_BY_LEARNER" &&
        currentStatus !== "REJECTED_BY_LEARNER")
    )
      throw new Error("Please select a course and status");

    const payload = {
      userId: sessionStorage.getItem("userId") || "",
      courseId: currentCourseId,
      status: currentStatus,
    };

    await dispatch(updateLearnerApplicationStatus(payload));
    await dispatch(getLearnerAppliedApplications());
    setCurrentCourseId(0);
    setCurrentStatus("");

    setIsSaveModalOpen(false);
  };

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
    const filteredData = appliedCourses.filter((course: any) => {
      return course.course_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCourses(filteredData);
  };

  const handleUpdateCourseApplication = async (
    course_id: number,
    status: string
  ) => {
    try {
      setCurrentStatus(status);
      setCurrentCourseId(course_id);
      setIsSaveModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  // if (loading) {
  //   return (
  //     <div className="flex items-center w-full justify-center">
  //       <SpinnerLoader />
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col items-start gap-8">
      <HomeButton />
      <Header />
      <TabNavbar id="" tabs={LEARNER_TABS} selectedTabId={2} />
      <div className="w-full h-full">
        <div className="w-full">
          <SearchWithFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            disabled={loading}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Course Name
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Type
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Language
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Date
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <div className="flex items-center w-full justify-center p-2">
                    <SpinnerLoader />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {paginatedData.length > 0 ? (
                  <>
                    {paginatedData?.map((item: any, i) => (
                      <tr key={i}>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.course_name}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.course_type || "n/a"}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.language || "n/a"}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.creation_date || "n/a"}
                        </td>
                        {item?.applications?.[0]?.application_status !==
                          "DRAFTED" ? (
                          <td
                            className={`border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5`}
                          >
                            <div
                              className={`m-2 min-w-[10rem] text-sm p-2 ${STATUS_MAP[
                                item?.applications?.[0]?.application_status
                              ]?.color || "bg-red-500 text-white"
                                }`}
                            >
                              {STATUS_MAP[
                                item?.applications?.[0]?.application_status
                              ]?.label || "N/A"}
                            </div>
                          </td>
                        ) : (
                          <td
                            className={`border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5`}
                          >
                            <div className={`m-2 min-w-[10rem] `}>
                              <div className="flex gap-2 align-center justify-center">
                                <button
                                  className="p-2 bg-green-500 text-white"
                                  onClick={() =>
                                    handleUpdateCourseApplication(
                                      item?.course_id,
                                      "CONFIRMED_BY_LEARNER"
                                    )
                                  }
                                >
                                  Accept
                                </button>
                                <button
                                  className="p-2 bg-red-500 text-white"
                                  onClick={() =>
                                    handleUpdateCourseApplication(
                                      item?.course_id,
                                      "REJECTED_BY_LEARNER"
                                    )
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className="pt-5">
                    <td colSpan={3} className="text-center">
                      No Data found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {loading === false && (
        <Pagination
          totalPages={totalPages}
          total_item_count={filteredCourses.length}
          handleChange={({ page, itemsPerPage }) => {
            setCurrentPage(page);
            setItemsPerPage(itemsPerPage);
          }}
        />
      )}
      {isSaveModalOpen && (
        <SaveConfirmModal
          closeModal={() => setIsSaveModalOpen(false)}
          handleSave={handleApply}
          loading={updateLoading}
          msg="Are you sure you want to proceed with this action?"
        />
      )}
    </div>
  );
}

export default ApplicationStatus;
