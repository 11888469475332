import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../../components/SpinnerLoader";
import { updateLearnerApplicationStatus } from "../../../redux/slices/learner.applications.slice";
import { RootState, useAppDispatch } from "../../../redux/store";
import SaveConfirmModal from "../../../components/modal/SaveConfirmModal";
import EventDetailsModal from "../../Sessions/components/EventDetailsModal";
import {
  getHRApplications,
  setEmptyAppliedCourses,
} from "../../../redux/slices/hr.applications.slice";
import HomeButton from "../../../components/HomeButton";
import Header from "../../../components/Header";
import TabNavbar from "../../../components/Tabbar";
import { HR_TABS } from "../../../utils/TabbarData";
import SearchWithFilter from "../../../components/SearchWithFilter";
import Pagination from "../../../components/Pagination";
const PRORITY_MAP: any = {
  NOT_SET: "Not Set",
  HIGH: "High",
  TOP: "Top",
};
function CourseApplications() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { hrApplicationLoading, appliedHRCourses, selectedCourseData } =
    useSelector((state: RootState) => state.hrApplications);

  const [modalData, setModalData] = useState<any[]>([]); // Modal data for events
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCourses, setFilteredCourses] =
    useState<any[]>(appliedHRCourses);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const totalPages =
    filteredCourses?.length > 0
      ? Math.ceil(filteredCourses?.length / itemsPerPage)
      : 1;
  const paginatedData = filteredCourses?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const handleApply = async () => {
    const payload = {
      userId: sessionStorage.getItem("userId") || "",
      courseId: selectedCourseData?.course_id,
      status: "APPLIED",
    };

    await dispatch(updateLearnerApplicationStatus(payload));

    setIsSaveModalOpen(false);
    navigate("/learner/applied-trainings");
  };

  useEffect(() => {
    dispatch(getHRApplications({ status: "HR_APPLICATIONS" }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredCourses(appliedHRCourses);
  }, [dispatch, appliedHRCourses]);
  // Parse and set modal data for events

  const handleCloseModal = () => setIsModalOpen(false);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    const filteredData = appliedHRCourses.filter((course: any) => {
      return course.user_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCourses(filteredData);
  };

  useEffect(() => {
    dispatch(setEmptyAppliedCourses());
  }, []);

  return (
    <div className="flex flex-col items-start gap-8">
      <HomeButton />
      <Header />
      <TabNavbar id="" tabs={HR_TABS} selectedTabId={4} />

      <div className="w-full h-full">
        <div className="w-full">
          <SearchWithFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            disabled={hrApplicationLoading}
          />
        </div>
        <table className="table-auto w-full mt-4">
          <thead>
            <tr className="bg-[#A6192E] text-white">
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Username
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Course Name
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Course Type
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Language
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Application Status
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Priority
              </th>
            </tr>
          </thead>
          <tbody>
            {hrApplicationLoading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  <div className="flex items-center w-full justify-center">
                    <SpinnerLoader />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {paginatedData.length > 0 ? (
                  <>
                    {paginatedData?.map((item: any, i: any) => (
                      <tr key={i}>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.user_name}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.course_name}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.course_type}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.language}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.application_status}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {PRORITY_MAP[item?.application_priority]}
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className="pt-5">
                    <td colSpan={5} className="text-center">
                      No Application Data found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          <EventDetailsModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            modalData={modalData}
          />
        </table>
      </div>
      {hrApplicationLoading === false && (
        <Pagination
          totalPages={totalPages}
          total_item_count={filteredCourses?.length}
          handleChange={({ page, itemsPerPage }) => {
            setCurrentPage(page);
            setItemsPerPage(itemsPerPage);
          }}
        />
      )}
      {isSaveModalOpen && (
        <SaveConfirmModal
          closeModal={() => setIsSaveModalOpen(false)}
          handleSave={handleApply}
          msg="Are you sure you want to apply for this course?"
        />
      )}
    </div>
  );
}

export default CourseApplications;
