import React from "react";

interface EventDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalData: {
    mode: string;
    eventName: string;
    date: string;
    time: string;
    location: string;
    classroom: string;
    instructors: string;
  }[];
}

const EventDetailsModal: React.FC<EventDetailsModalProps> = ({
  isOpen,
  onClose,
  modalData,
}) => {
  if (!isOpen) return null;
  console.log("modalData====", modalData);
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-5 rounded shadow-lg">
        <h2 className="text-lg font-semibold mb-4">Event Details</h2>
        <table className="table-auto w-full border-collapse">
          <thead className="bg-[#961e38] w-full text-white rounded-t-xl">
            <tr>
              <th className="border-b py-2 px-4">Event Name</th>
              <th className="border-b py-2 px-4">Date</th>
              <th className="border-b py-2 px-4">Time</th>
              <th className="border-b py-2 px-4">Mode</th>
              <th className="border-b py-2 px-4">Location</th>
              <th className="border-b py-2 px-4">Classroom</th>
              <th className="border-b py-2 px-4">Instructors</th>
            </tr>
          </thead>
          <tbody>
            {modalData.map((event, idx) => (
              <tr key={idx}>
                <td className="border-b py-2 px-4">{event.eventName}</td>
                <td className="border-b py-2 px-4">{event.date}</td>
                <td className="border-b py-2 px-4">{event.time}</td>
                <td className="border-b py-2 px-4">{event.mode}</td>
                <td className="border-b py-2 px-4">{event.location}</td>
                <td className="border-b py-2 px-4">{event.classroom}</td>
                <td className="border-b py-2 px-4">
                  {event.instructors
                    .split(",")
                    .map((instructor: any, index) => (
                      <span key={index}>
                        {`${instructor.trim()},`}
                        <br />
                      </span>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={onClose}
          className="mt-4 py-2 px-4 bg-red-500 text-white rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default EventDetailsModal;
