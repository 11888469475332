import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getQueryString } from "../../utils/helper";
import { ManagerCourse } from "../../interfaces/manager.application";

const initialState = {
  loading: false,
  availableCourses: [] as ManagerCourse[],
  appliedCourses: [] as ManagerCourse[],
  myTraingHistories: [],
  updateLoading: false,
  availableCourseSessions: [] as any[],
  selectedCourse: {} as ManagerCourse,
};

export const getLearnerAvailableApplications = createAsyncThunk(
  "learnerApplications/getLearnerAvailableApplications",
  async (_, { getState, rejectWithValue }) => {
    const query = getQueryString({
      user_id: sessionStorage.getItem("userId"),
      role: "learner",
      status: "LEARNER_AVAILABLE",
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    return response.data;
  }
);

export const getLearnerAppliedApplications = createAsyncThunk(
  "learnerApplications/getLearnerAppliedApplications",
  async (_, { getState, rejectWithValue }) => {
    const query = getQueryString({
      user_id: sessionStorage.getItem("userId"),
      role: "learner",
      status: "LEARNER_APPLIED",
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    return response.data;
  }
);

export const getLearnerMyTrainingHistory = createAsyncThunk(
  "learnerApplications/getLearnerMyTrainingHistory",
  async (_, { getState, rejectWithValue }) => {
    const query = getQueryString({
      user_id: sessionStorage.getItem("userId"),
      role: "learner",
      status: "LEARNER_PAST",
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications${query}`;

    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json());

    return response.data;
  }
);

export const getLearnerCourseSessions = createAsyncThunk(
  "learnerApplications/getCourseSessions",
  async (
    props: { course_id: string; role: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const query = getQueryString(props);

      const url = `${process.env.REACT_APP_BASE_API_URL}/sessions${query}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const updateLearnerApplicationStatus = createAsyncThunk(
  "learnerApplications/updateLearnerApplicationStatus",
  async (props: {
    status: string;
    courseId: string | number;
    userId: string | number;
  }) => {
    const query = getQueryString({
      user_id: props.userId,
      role: "learner",
      course_id: props.courseId,
    });

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications/${query}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        application_status: props.status,
      }),
    }).then((res) => res.json());

    return response.data;
  }
);

export const learnerApplicationsSlice = createSlice({
  name: "learnerApplications",
  initialState,
  reducers: {
    setSelectedCourse: (state, action) => {
      state.selectedCourse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerAvailableApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLearnerAvailableApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.availableCourses = action.payload;
      })
      .addCase(getLearnerAvailableApplications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLearnerAppliedApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLearnerAppliedApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.appliedCourses = action.payload;
      })
      .addCase(getLearnerAppliedApplications.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLearnerMyTrainingHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLearnerMyTrainingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.myTraingHistories = action.payload;
      })
      .addCase(getLearnerMyTrainingHistory.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getLearnerCourseSessions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLearnerCourseSessions.fulfilled, (state, action) => {
        state.loading = false;
        state.availableCourseSessions = action.payload || [];
      })
      .addCase(getLearnerCourseSessions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateLearnerApplicationStatus.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateLearnerApplicationStatus.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateLearnerApplicationStatus.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default learnerApplicationsSlice.reducer;

export const { setSelectedCourse } = learnerApplicationsSlice.actions;
