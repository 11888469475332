import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import CourseCard from "../../components/Card/CourseCard";
import Header from "../../components/Header";
import SearchWithFilter from "../../components/SearchWithFilter";
import SpinnerLoader from "../../components/SpinnerLoader";
import {
  getManagerApplications,
  setSelectedCourseApplication,
} from "../../redux/slices/manager.applications.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import Pagination from "../../components/Pagination";
import TabNavbar from "../../components/Tabbar";
import { MANAGER_TABS } from "../../utils/TabbarData";

function ManagerCourseApplications() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { loading, managerCourses } = useSelector(
    (state: RootState) => state.managerApplications
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [itemsPerPage, setItemsPerPage] = useState(4);

  const [filteredCourses, setFilteredCourses] = useState<any[]>(managerCourses);

  const totalPages =
    filteredCourses.length > 0
      ? Math.ceil(filteredCourses?.length / itemsPerPage)
      : 1;

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
    const filteredData = managerCourses.filter((course: any) => {
      return course.course_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCourses(filteredData);
  };

  const handleNavigateToCourseSessions = (id: number | string) => {
    const courseById = managerCourses.find((course) => course.course_id === id);

    if (!courseById) return;
    dispatch(setSelectedCourseApplication(courseById));

    navigate("/manager/courses/" + id);
  };

  useEffect(() => {
    dispatch(getManagerApplications());
  }, [dispatch]);

  const paginatedData = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    setFilteredCourses(managerCourses);
  }, [managerCourses]);

  return (
    <div className="flex flex-col items-start gap-8">
      <BackButton />
      <Header />
      <TabNavbar id="" tabs={MANAGER_TABS} selectedTabId={1} />
      <div className="w-full">
        <SearchWithFilter
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          disabled={loading}
        />
      </div>
      {loading ? (
        <div className="flex items-center w-full justify-center">
          <SpinnerLoader />
        </div>
      ) : (
        <div className="flex flex-col w-full justify-start items-start gap-8 px-10">
          {paginatedData.map((item: any) => (
            <>
              <CourseCard
                handleNavigateToCourseSessions={handleNavigateToCourseSessions}
                item={item}
              />
            </>
          ))}
          <Pagination
            totalPages={totalPages}
            total_item_count={filteredCourses.length}
            handleChange={({ page, itemsPerPage }) => {
              setCurrentPage(page);
              setItemsPerPage(itemsPerPage);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ManagerCourseApplications;
