import { useNavigate } from "react-router-dom";
import { Tabs } from "../../utils/TabbarData";

function TabNavbar({
  id,
  handleSaveAndPublish,
  isDisable,
  isLoading,
  tabs,
  selectedTabId,
  doesShow,
}: {
  id: string;
  handleSaveAndPublish?: () => void;
  isDisable?: boolean;
  isLoading?: boolean;
  tabs: Tabs[];
  selectedTabId?: number;
  doesShow?: boolean;
}) {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-between gap-5 w-full">
      <div className="flex items-center justify-start gap-5 w-full">
        {tabs.map((item) => {
          return (
            <div
              className={`flex items-center justify-start gap-2 cursor-pointer group ${
                item.id === selectedTabId && "underline underline-offset-2"
              } duration-200 transition-all decoration-[#752938]`}
              onClick={() => {
                if (item.title === "MY TRAINING HISTORY") {
                  // Open URL in a new tab
                  if (item.url !== "#") {
                    window.open(item.url, "_blank");
                  }
                } else {
                  // Navigate as usual
                  if (item.url !== "#") {
                    navigate(item.url);
                  }
                }
              }}
            >
              <div>{item.icon}</div>
              <p className="text-sm text-[#752938]">{item.title}</p>
            </div>
          );
        })}
      </div>
      {id === "seat-allocation" && doesShow && (
        <div className="flex items-center gap-5 w-full justify-end">
          <button
            disabled={isDisable || isLoading}
            onClick={() => handleSaveAndPublish && handleSaveAndPublish()}
            className={`py-2 px-4 font-semibold bg-[#961e38] text-white ${
              isDisable ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {isLoading
              ? "SAVING..."
              : isDisable
              ? "Training Published"
              : "SAVE & PUBLISH TRAINING"}
          </button>
        </div>
      )}
    </div>
  );
}

export default TabNavbar;
