import React from "react";
import { IoChevronForward } from "react-icons/io5";

interface CourseCardProps {
  item: any;
  handleNavigateToCourseSessions: (courseId: number) => void;
}

function CourseCard({ handleNavigateToCourseSessions, item }: CourseCardProps) {
  const { course_name, course_type, language, creation_date } = item;

  return (
    <>
      <div
        key={item.course_id}
        onClick={() => handleNavigateToCourseSessions(item.course_id)}
        className="flex flex-row w-full justify-between items-center group cursor-pointer"
      >
        <div className="flex flex-col w-full">
          {course_name && (
            <p className="text-2xl font-semibold text-[#961e38]">
              {course_name}
            </p>
          )}
          <div className="flex items-center justify-start gap-5 pt-2">
            {course_type && (
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Type:</span>
                <span>{course_type}</span>
              </p>
            )}
            {language && (
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Lang:</span>
                <span>{language}</span>
              </p>
            )}
            {creation_date && (
              <p className="text-xs font-semibold flex items-center justify-start gap-2">
                <span className="text-[#b5b5b5]">Creation Date:</span>
                <span>{creation_date}</span>
              </p>
            )}
          </div>
        </div>
        <IoChevronForward
          size={40}
          className="text-[#464646] group-hover:text-[#961e38] transition-colors duration-300"
        />
      </div>
      <div className="border border-[#b5b5b5] w-full" />
    </>
  );
}

export default CourseCard;
