/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useOutlet } from "react-router-dom";
import SpinnerLoader from "../../components/SpinnerLoader";
import { getAuth, getUserRoles } from "../../redux/slices/auth.slice";
import { RootState, useAppDispatch } from "../../redux/store";

const ProtectedLayout = () => {
  const outlet = useOutlet();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { loading, isAuthorized } = useSelector(
    (state: RootState) => state.auth
  );

  const callAuthAndUser = async () => {
    await dispatch(getAuth());
    await dispatch(getUserRoles());
  };

  useEffect(() => {
    callAuthAndUser();
  }, [dispatch]);

  // useEffect(() => {
  //   if (isAuthorized && pathname === "/") navigate("/courses");
  // }, [isAuthorized, pathname]);

  if (loading)
    return (
      <div className="flex items-center w-full justify-center">
        <SpinnerLoader />
      </div>
    );

  return outlet;
};

export default ProtectedLayout;
