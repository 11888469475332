import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import SpinnerLoader from "../../../../components/SpinnerLoader";
import { updateLearnerApplicationStatus } from "../../../../redux/slices/learner.applications.slice";
import { RootState, useAppDispatch } from "../../../../redux/store";
import SaveConfirmModal from "../../../../components/modal/SaveConfirmModal";
import EventDetailsModal from "../../../Sessions/components/EventDetailsModal";
import {
  getHRApplications,
  updateHRApplicationStatus,
} from "../../../../redux/slices/hr.applications.slice";
import { FaEllipsisH } from "react-icons/fa";
import SearchWithFilter from "../../../../components/SearchWithFilter";
const PRORITY_MAP: any = {
  NOT_SET: "Not Set",
  HIGH: "High",
  TOP: "Top",
};
function CourseApplications() {
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    loading,
    appliedHRCourses,
    selectedCourseData,
    applidCourseMetaData,
    updateLoading,
  } = useSelector((state: RootState) => state.hrApplications);

  const [modalData, setModalData] = useState<any[]>([]); // Modal data for events
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState<number | null>(null); // Track which menu is open
  const [filteredCourses, setFilteredCourses] =
    useState<any[]>(appliedHRCourses);
  const [searchTerm, setSearchTerm] = useState("");
  const menuRef = useRef<HTMLDivElement | null>(null); // Reference for Menu Modal
  const confirmationRef = useRef<HTMLDivElement | null>(null); // Reference for Confirmation Modal
  const [confirmationMenu, setConfirmationMenu] = useState<{
    action: string;
    item: any;
  } | null>(null);

  // Close modals when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      console.log("Event target:", event.target);
      console.log("Menu ref:", menuRef.current);
      console.log("Confirmation ref:", confirmationRef.current);

      if (
        (menuRef.current && !menuRef.current.contains(event.target as Node)) ||
        (confirmationRef.current &&
          !confirmationRef.current.contains(event.target as Node))
      ) {
        setMenuOpen(null); // Close the menu modal
        setConfirmationMenu(null); // Close the confirmation modal
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     console.log("Click detected outside, closing modals...");
  //     setMenuOpen(null);
  //     setConfirmationMenu(null);
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const handleMenuClick = (index: number) => {
    setMenuOpen(menuOpen === index ? null : index); // Toggle menu open/close
  };

  const handleOptionSelect = (action: string, item: any) => {
    setMenuOpen(null); // Close menu
    setConfirmationMenu({ action, item }); // Open confirmation menu
  };
  const { id } = useParams();

  const handleConfirmation = async (confirm: boolean) => {
    if (confirm && confirmationMenu) {
      const { action, item } = confirmationMenu;

      const dataToSend: any = {
        course_id: item.course_id,
        user_id: item.user_id,
      };
      // Example: Call Redux API based on action
      if (action === "REMOVE_PRIORITY") {
        dataToSend.application_status = "WAITING";
        dataToSend.priority = "NOT_SET";
      } else if (action === "PRIORITIZE_TOP") {
        dataToSend.application_status = "WAITING";
        dataToSend.priority = "TOP";
      } else if (action === "PRIORITIZE_HIGH") {
        dataToSend.application_status = "WAITING";
        dataToSend.priority = "HIGH";
      } else {
        dataToSend.application_status = "REJECTED_BY_HR";
      }

      setConfirmationMenu(null); // Close confirmation menu
      await dispatch(updateHRApplicationStatus(dataToSend));
    } else {
      setConfirmationMenu(null); // Close confirmation menu
    }
  };

  const handleApply = async () => {
    const payload = {
      userId: sessionStorage.getItem("userId") || "",
      courseId: selectedCourseData?.course_id,
      status: "APPLIED",
    };

    await dispatch(updateLearnerApplicationStatus(payload));

    setIsSaveModalOpen(false);
    navigate("/learner/applied-trainings");
  };

  useEffect(() => {
    setFilteredCourses(appliedHRCourses);
  }, [appliedHRCourses]);

  useEffect(() => {
    if (id) {
      dispatch(getHRApplications({ course_id: id, status: "HR_APPROVAL" }));
    }
  }, [pathname, dispatch]);
  // Parse and set modal data for events
  const handleOpenModal = (events: string) => {
    // Step 1: Split by "," only outside of "({ })"
    const eventBlocks = events.split(/(?<=\)}),/); // Match ", " only outside the "({ })"
    console.log(eventBlocks);
    const parsedEvents = eventBlocks.map((block) => {
      // Step 2: Split by "({"
      const [eventName, detailsBlock] = block.split("{(");

      // Step 3: Remove closing ")}" and split details inside "({ })" by ","
      const details = detailsBlock
        ?.replace(")}", "")
        ?.split(",")
        .map((item) => item.trim());

      // Step 4: Map details to fields
      const [date, time, mode, location, classroom, ...instructorsBlock] =
        details || [];

      console.log(instructorsBlock);
      // Step 5: Clean instructors
      // Step 5: Extract and clean instructors
      const instructors = instructorsBlock
        .join(", ") // Join remaining parts to recreate full instructors string
        .replace("Instructors:", "") // Remove the "Instructors:" prefix
        .trim();

      return {
        eventName: eventName?.trim(),
        date: date || null,
        time: time || null,
        mode: mode || null,
        location: location || null,
        classroom: classroom || null,
        instructors: instructors || null,
      };
    });

    setModalData(parsedEvents);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    const filteredData = appliedHRCourses.filter((course: any) => {
      return course.user_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCourses(filteredData);
  };

  return (
    <div className="flex flex-col items-start justify-start gap-10">
      <BackButton />
      <div className="flex flex-row w-full justify-between items-center group cursor-pointer">
        <div className="flex flex-col w-full">
          <p className="text-2xl font-semibold text-[#961e38]">
            {selectedCourseData?.course_name}
          </p>
          <div className="flex items-center justify-start gap-5 pt-2">
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">Category:</span>
              <span>{selectedCourseData?.category_name}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">Lang:</span>
              <span>{selectedCourseData?.language}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">Creation Date:</span>
              <span>{selectedCourseData?.creation_date}</span>
            </p>
          </div>
        </div>
      </div>

      <div className="w-full h-full">
        <div className="w-full">
          <SearchWithFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            disabled={loading}
          />
        </div>
        <div className="relative w-full mt-4">
          <table className="table-auto w-full">
            <thead className="bg-[#f8f9fa]">
              <tr className="bg-[#A6192E] text-white">
                <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                  Username
                </th>
                <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                  Application Status
                </th>
                <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                  Priority
                </th>
                <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                  Actions
                </th>
              </tr>
            </thead>
          </table>
          <div className="overflow-y-auto max-h-[300px]">
            <table className="table-auto w-full">
              <tbody>
                {loading || updateLoading ? (
                  <tr>
                    <td colSpan={4} className="text-center">
                      <div className="flex items-center w-full justify-center">
                        <SpinnerLoader />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {filteredCourses?.length > 0 ? (
                      <div className="max-h-[300px] overflow-y-auto w-full">
                        {filteredCourses?.map((item: any, i: any) => (
                          <tr key={i}>
                            <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                              {item?.user_name}
                            </td>
                            <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                              {item?.application_status}
                            </td>
                            <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                              {PRORITY_MAP[item?.application_priority]}
                            </td>
                            <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                              {updateLoading && i === menuOpen ? (
                                <div className="flex items-center w-full justify-center">
                                  <SpinnerLoader />
                                </div>
                              ) : (
                                <button
                                  onClick={() => {
                                    setMenuOpen(null); // Close menu
                                    setConfirmationMenu(null);
                                    setIsSaveModalOpen(false);
                                    handleMenuClick(i);
                                  }}
                                  className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                  title="More options"
                                >
                                  <FaEllipsisH size={20} />
                                </button>
                              )}
                              {/* Conditional Menu */}
                              {menuOpen === i && (
                                <div
                                  ref={menuRef} // Attach the ref to the menu modal
                                  className="absolute right-0 z-10 bg-white shadow-md border rounded"
                                >
                                  <ul>
                                    {item.application_status === "WAITING" &&
                                      item.application_priority === "TOP" &&
                                      applidCourseMetaData?.total_high_avail >
                                        0 && (
                                        <li
                                          onClick={() =>
                                            handleOptionSelect(
                                              "PRIORITIZE_HIGH",
                                              item
                                            )
                                          }
                                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                          Send to High Priority
                                        </li>
                                      )}
                                    {item.application_status === "WAITING" &&
                                      item.application_priority === "NOT_SET" &&
                                      applidCourseMetaData?.total_top_avail >
                                        0 && (
                                        <li
                                          onClick={() =>
                                            handleOptionSelect(
                                              "PRIORITIZE_TOP",
                                              item
                                            )
                                          }
                                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                          Send to Top Priority
                                        </li>
                                      )}
                                    {(item.application_priority === "HIGH" ||
                                      item.application_priority === "TOP") &&
                                      item.application_status === "WAITING" && (
                                        <li
                                          onClick={() =>
                                            handleOptionSelect(
                                              "REMOVE_PRIORITY",
                                              item
                                            )
                                          }
                                          className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                        >
                                          Remove Priority
                                        </li>
                                      )}
                                    <li
                                      onClick={() =>
                                        handleOptionSelect(
                                          "REMOVE_APPLICATION",
                                          item
                                        )
                                      }
                                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-red-500"
                                    >
                                      Remove User's Application
                                    </li>
                                  </ul>
                                </div>
                              )}
                              {/* Confirmation Menu */}
                              {confirmationMenu &&
                                confirmationMenu.item === item && (
                                  <div
                                    ref={confirmationRef}
                                    className="absolute right-0 z-20 bg-white shadow-md border rounded"
                                  >
                                    <p className="px-4 py-2">Are you sure?</p>
                                    <div className="flex justify-around px-4 py-2">
                                      <button
                                        onClick={() => handleConfirmation(true)}
                                        className="text-green-500 hover:underline"
                                      >
                                        Yes
                                      </button>

                                      <button
                                        onClick={() =>
                                          handleConfirmation(false)
                                        }
                                        className="text-red-500 hover:underline"
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </td>
                          </tr>
                        ))}
                      </div>
                    ) : (
                      <tr className="pt-5">
                        <td colSpan={5} className="text-center">
                          No Application Data found for{" "}
                          {selectedCourseData.course_name}
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
              <EventDetailsModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                modalData={modalData}
              />
            </table>
            <div className="bg-[#A6192E] w-full text-white h-10 mt-4"></div>
          </div>
        </div>
      </div>
      {isSaveModalOpen && (
        <SaveConfirmModal
          closeModal={() => setIsSaveModalOpen(false)}
          handleSave={handleApply}
          msg="Are you sure you want to apply for this course?"
        />
      )}
    </div>
  );
}

export default CourseApplications;
