import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CourseCard from "../../components/Card/CourseCard";
import Header from "../../components/Header/index";
import SearchWithFilter from "../../components/SearchWithFilter";
import SpinnerLoader from "../../components/SpinnerLoader";
import TabNavbar from "../../components/Tabbar";
import { getCourses } from "../../redux/slices/couses.slice";
import { setSelectedCourseId } from "../../redux/slices/session.slice";
import { RootState, useAppDispatch } from "../../redux/store";
import BackButton from "../../components/BackButton";
import { ADMIN_TABS } from "../../utils/TabbarData";

function Courses() {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const [itemsPerPage, setItemsPerPage] = useState(4);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateToCourseSessions = (id: number | string) => {
    const courseById = courses.find((course) => course.course_id === id);
    if (!courseById) return;
    dispatch(setSelectedCourseId(courseById));

    navigate("/courses/sessions/" + id);
  };

  const { courseResp, courses, loading } = useSelector(
    (state: RootState) => state.course
  );

  // Handle page change
  const handlePageChange = (page: number) => setCurrentPage(page);

  // Handle search input
  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    // Fetch courses from the API
    dispatch(
      getCourses({
        page_size: itemsPerPage,
        page: currentPage,
        filter_value: searchTerm, // Pass search term to API
      })
    );
  }, [currentPage, searchTerm, dispatch, itemsPerPage]);

  // Rendered data (from API)
  const totalPages = Math.ceil(
    (courseResp?.total_item_count || 0) / itemsPerPage
  );

  return (
    <div className="flex flex-col items-start gap-8">
      <BackButton />
      <Header />
      <TabNavbar id="courses" tabs={ADMIN_TABS} selectedTabId={1} />
      {/* Search and Filter */}
      <div className="w-full">
        <SearchWithFilter
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
          disabled={loading}
        />
      </div>
      {/* Course List */}
      {loading ? (
        <div className="flex items-center w-full justify-center">
          <SpinnerLoader />
        </div>
      ) : (
        <div className="flex flex-col w-full justify-start items-start gap-8 px-10">
          {courses.map((item) => (
            <>
              <CourseCard
                handleNavigateToCourseSessions={handleNavigateToCourseSessions}
                item={item}
              />
            </>
          ))}
          {/* Pagination Controls */}
          {courses.length > 0 && !loading && (
            <div className="flex justify-between items-center w-full">
              <div className="flex justify-center items-center gap-4 mt-4">
                {/* Previous Button */}
                <button
                  className={`px-4 py-2 border rounded ${
                    currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>

                {/* Dynamic Page Buttons */}
                {Array.from({ length: Math.min(totalPages, 4) }).map(
                  (_, index) => {
                    const startPage = Math.max(
                      1,
                      Math.min(currentPage - 1, totalPages - 3)
                    );
                    const pageNumber = startPage + index;

                    return (
                      <button
                        key={pageNumber}
                        className={`px-4 py-2 border rounded ${
                          currentPage === pageNumber
                            ? "bg-[#961e38] text-white"
                            : "bg-white text-[#961e38]"
                        }`}
                        onClick={() => handlePageChange(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    );
                  }
                )}

                {/* Next Button */}
                <button
                  className={`px-4 py-2 border rounded ${
                    currentPage === totalPages
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  }`}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
              <div className="flex justify-center items-center gap-5">
                <p className="text-sm font-semibold">
                  Showing {itemsPerPage * (currentPage - 1) + 1} to{" "}
                  {Math.min(
                    itemsPerPage * currentPage,
                    courseResp?.total_item_count || 0
                  )}{" "}
                  of {courseResp?.total_item_count || 0} results
                </p>
                <div className="flex items-center gap-3">
                  <p className="text-sm font-semibold">Items per page:</p>
                  <select
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  >
                    <option value={4}>4</option>
                    <option value={8}>8</option>
                    <option value={12}>12</option>
                  </select>
                </div>
              </div>
            </div>
            // <Pagination
            //   totalPages={totalPages}
            //   total_item_count={courseResp?.total_item_count || 0}
            //   handleChange={({ itemsPerPage, page }) => {
            //     setCurrentPage(page);
            //     setItemsPerPage(itemsPerPage);
            //   }}
            // />
          )}
        </div>
      )}
    </div>
  );
}

export default Courses;
