import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Header from "../../../components/Header";
import HomeButton from "../../../components/HomeButton";
import SpinnerLoader from "../../../components/SpinnerLoader";
import TabNavbar from "../../../components/Tabbar";
import { getLearnerMyTrainingHistory } from "../../../redux/slices/learner.applications.slice";
import { RootState, useAppDispatch } from "../../../redux/store";
import { LEARNER_TABS } from "../../../utils/TabbarData";
import { formatString } from "../../../utils/helper";
import Pagination from "../../../components/Pagination";
import SearchWithFilter from "../../../components/SearchWithFilter";

const STATUS_MAP: Record<string, { label: string; color: string }> = {
  REJECTED_BY_MANAGER: {
    label: "Denied By Manager",
    color: "bg-red-500 text-white",
  },
  REJECTED_BY_LEARNER: {
    label: "You Rejected",
    color: "bg-gray-500 text-white",
  },
  REJECTED_BY_TOA: {
    label: "Rejected due to not confirming seat",
    color: "bg-orange-500 text-white",
  },
  ENDED: {
    label: "Session Completed",
    color: "bg-green-500 text-white",
  },
};

function MyTrainingHistory() {
  const [currentPage, setCurrentPage] = useState(1); // Current page state

  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useAppDispatch();

  const { myTraingHistories, loading } = useSelector(
    (state: RootState) => state.learnerApplications
  );

  const [filteredCourses, setFilteredCourses] =
    useState<any[]>(myTraingHistories);

  const totalPages =
    filteredCourses.length > 0
      ? Math.ceil(filteredCourses?.length / itemsPerPage)
      : 1;

  const paginatedData = filteredCourses.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
    const filteredData = myTraingHistories.filter((course: any) => {
      return course.course_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredCourses(filteredData);
  };

  useEffect(() => {
    setFilteredCourses(myTraingHistories);
  }, [myTraingHistories]);

  useEffect(() => {
    dispatch(getLearnerMyTrainingHistory());
  }, [dispatch]);

  // if (loading) {
  //   return (
  //     <div className="flex items-center w-full justify-center">
  //       <SpinnerLoader />
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col items-start gap-8">
      <HomeButton />
      <Header />
      <TabNavbar id="" tabs={LEARNER_TABS} selectedTabId={3} />
      <div className="w-full h-full">
        <div className="w-full">
          <SearchWithFilter
            searchTerm={searchTerm}
            onSearchChange={handleSearchChange}
            disabled={loading}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Course Name
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Type
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Language
              </th>
              <th className="border-b border-[#961e38] py-2 px-4 w-1/5 text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <div className="flex items-center w-full justify-center p-2">
                    <SpinnerLoader />
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {paginatedData.length > 0 ? (
                  <>
                    {paginatedData?.map((item: any, i: any) => (
                      <tr key={i}>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.course_name}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.course_type || "n/a"}
                        </td>
                        <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {item?.language || "n/a"}
                        </td>
                        {/* <td className="border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5">
                          {formatString(
                            item?.applications?.[0].application_status
                          ) || "n/a"}
                        </td> */}
                        <td
                          className={`border-b border-[#c6c6c6] py-2 px-4 text-center w-1/5 `}
                        >
                          <div
                            className={`text-sm m-2 min-w-[10rem] p-2 ${
                              STATUS_MAP[
                                item?.applications?.[0]?.application_status
                              ]?.color || "bg-red-500 text-white"
                            }`}
                          >
                            {" "}
                            {STATUS_MAP[
                              item?.applications?.[0]?.application_status
                            ]?.label || "N/A"}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr className="pt-5">
                    <td colSpan={3} className="text-center">
                      No Data found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {loading === false && (
        <Pagination
          totalPages={totalPages}
          total_item_count={filteredCourses.length}
          handleChange={({ page, itemsPerPage }) => {
            setCurrentPage(page);
            setItemsPerPage(itemsPerPage);
          }}
        />
      )}
    </div>
  );
}

export default MyTrainingHistory;
