import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Courses from "./pages/Courses";
import HomePage from "./pages/Home";
import SeatAllocation from "./pages/SeatAllocations";
import Sessions from "./pages/Sessions";
import ProtectedLayout from "./layout/ProtectedLayout";
import ManagerCourseApplications from "./pages/Manager";
import ManagerApplications from "./pages/Manager/CourseApplications";
import AvailableTrainings from "./pages/Learner/AvailableTrainings";
import ApplicationStatus from "./pages/Learner/ApplicationStatus";
import CourseSessions from "./pages/Learner/AvailableTrainings/CourseSessions"; //c
import MyTrainingHistory from "./pages/Learner/MyTrainingHistory";
import AllTrainings from "./pages/Hr/AllTrainings";
import CourseApplications from "./pages/Hr/AllTrainings/CourseApplications";
import UserApplication from "./pages/Hr/UserApplications";

function App() {
  return (
    <div className="w-4/5 m-auto px-40 py-16">
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<ProtectedLayout />}>
            <Route path={"/"} element={<HomePage />} />
            <Route path={"/manager"} element={<ManagerCourseApplications />} />
            <Route
              path={"/manager/courses/:id"}
              element={<ManagerApplications />}
            />
            <Route
              path={"/learner/available-trainings"}
              element={<AvailableTrainings />}
            />
            <Route
              path={"/learner/available-trainings/:id"}
              element={<CourseSessions />}
            />
            <Route
              path={"/learner/applied-trainings"}
              element={<ApplicationStatus />}
            />

            <Route
              path={"/learner/my-trainings-history"}
              element={<MyTrainingHistory />}
            />
            <Route path={"/courses"} element={<Courses />} />
            <Route path={"/courses/sessions/:id"} element={<Sessions />} />
            <Route
              path={"/courses/sessions/:id/seat-allocation/:id"}
              element={<SeatAllocation />}
            />

            {/* ====================== HR ================================== */}
            <Route path={"/hr/all-trainings"} element={<AllTrainings />} />
            <Route
              path={"/hr/all-training/course-applications/:id"}
              element={<CourseApplications />}
            />
            <Route
              path={"/hr/user-applications"}
              element={<UserApplication />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
