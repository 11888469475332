export const getQueryString = (params: any) => {
  let query = "?";

  for (const [key, value] of Object.entries(params)) {
    query += `${key}=${value}&`;
  }
  query = query.slice(0, -1);

  return query;
};

export const formatString = (str: string) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
