import React, { useId, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store";
import BackButton from "../../../components/BackButton";
import Header from "../../../components/Header";
import {
  getManagerApplications,
  setSelectedCourseApplication,
  updateManagerApplicationStatus,
} from "../../../redux/slices/manager.applications.slice";
import { formatString } from "../../../utils/helper";
import SpinnerLoader from "../../../components/SpinnerLoader";
import SaveConfirmModal from "../../../components/modal/SaveConfirmModal";
const STATUS_MAP: any = {
  REJECTED_BY_MANAGER: {
    label: "Denied",
    color: "bg-red-500 text-white",
  },
  APPROVED_BY_MANAGER: {
    label: "Accepted",
    color: "bg-green-500 text-white",
  },
};

function ManagerApplications() {
  const { selectedCourseApplication, updateLoading, managerCourses, loading } =
    useSelector((state: RootState) => state.managerApplications);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>("");
  const [currentCourseId, setCurrentCourseId] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const dispatch = useAppDispatch();

  const {
    applications,
    course_id,
    course_name,
    course_type,
    language,
    creation_date,
  } = selectedCourseApplication;

  console.log("updated====");

  const handleApply = async () => {
    if (
      currentUserId === null ||
      currentCourseId === 0 ||
      (currentStatus !== "APPROVED_BY_MANAGER" &&
        currentStatus !== "REJECTED_BY_MANAGER")
    )
      throw new Error("Please select a course and status");

    const payload = {
      userId: currentUserId,
      courseId: course_id,
      status: currentStatus,
    };

    await dispatch(updateManagerApplicationStatus(payload));
    setCurrentCourseId(0);
    setCurrentStatus("");
    setCurrentUserId(null);

    setIsSaveModalOpen(false);
    // Step 2: Re-fetch the updated list of manager applications
    const response = await dispatch(getManagerApplications());

    console.log("Response", response.payload);
    // Step 3: Update the selected course application after fetching
    if (response.payload) {
      const updatedCourse = response.payload.data.find(
        (course: any) => course.course_id === course_id
      );
      console.log("updatedCourse====", updatedCourse);

      if (updatedCourse) {
        console.log("updatedCourse", updatedCourse);
        dispatch(setSelectedCourseApplication(updatedCourse));
      }
    }
    setCurrentCourseId(0);
    setCurrentStatus("");

    setIsSaveModalOpen(false);
  };

  const handleUpdateCourseApplication = async (
    status: string,
    userId: number
  ) => {
    try {
      setCurrentUserId(userId);
      setCurrentStatus(status);
      setCurrentCourseId(course_id);
      setIsSaveModalOpen(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="flex flex-col items-start gap-8">
      <BackButton />
      <Header />
      <div className="flex flex-row w-full justify-between items-center group cursor-pointer">
        <div className="flex flex-col w-full">
          <p className="text-2xl font-semibold text-[#961e38]">{course_name}</p>
          <div className="flex items-center justify-start gap-5 pt-2">
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">Type:</span>
              <span>{course_type}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">Lang:</span>
              <span>{language}</span>
            </p>
            <p className="text-xs font-semibold flex items-center justify-start gap-2">
              <span className="text-[#b5b5b5]">Creation Date:</span>
              <span>{creation_date || "N/A"}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-full">
        <table className="w-full border-collapse">
          <thead className="bg-[#961e38] w-full text-white rounded-t-xl">
            <tr>
              <th className="py-2 px-4 text-left">Users</th>
              <th className="py-2 px-4 text-right">Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={2} className="py-2 px-4 text-center">
                  <SpinnerLoader />
                </td>
              </tr>
            ) : applications.length > 0 ? (
              applications.map((application) => {
                const { application_status, user_id, user_name } = application;
                return (
                  <tr key={user_id}>
                    <td className="border-b border-[#c6c6c6] py-2 px-4 text-left">
                      {user_name}
                    </td>
                    <td
                      className={`border-b border-[#c6c6c6] py-2 px-4 text-right `}
                    >
                      {application_status === "APPLIED" ? (
                        <div className="flex items-center gap-3">
                          <button
                            className={`py-1 px-5 cursor-pointer font-semibold bg-[#961e38] text-white ${
                              updateLoading && "opacity-50 cursor-not-allowed"
                            }}`}
                            onClick={() => {
                              handleUpdateCourseApplication(
                                "APPROVED_BY_MANAGER",
                                user_id
                              );
                            }}
                            disabled={updateLoading}
                          >
                            Approve
                          </button>
                          <button
                            className={`py-1 px-5 cursor-pointer font-semibold bg-[#961e38] text-white ${
                              updateLoading && "opacity-50 cursor-not-allowed"
                            }}`}
                            onClick={() => {
                              handleUpdateCourseApplication(
                                "REJECTED_BY_MANAGER",
                                user_id
                              );
                            }}
                            disabled={updateLoading}
                          >
                            Deny
                          </button>
                        </div>
                      ) : (
                        <div
                          className={`flex items-center justify-center mt-2 p-3 ${
                            STATUS_MAP[application_status]?.color ||
                            "bg-red-500 text-white"
                          }`}
                        >
                          {STATUS_MAP[application_status]?.label || "N/A"}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={2}>
                  <p className="w-full text-center py-5">No Data Available</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isSaveModalOpen && (
          <SaveConfirmModal
            closeModal={() => setIsSaveModalOpen(false)}
            handleSave={handleApply}
            loading={updateLoading}
            msg={`Are you sure you want to ${
              currentStatus === "APPROVED_BY_MANAGER" ? "accept" : "deny"
            } the application?`}
          />
        )}
      </div>
    </div>
  );
}

export default ManagerApplications;

{
  /* <tfoot className="bg-[#961e38]">
            <tr>
              <td colSpan={2} className="p-5 text-white rounded-b-xl">
                <div className="flex items-center justify-between rounded-b-xl">
                  <div className="flex items-center gap-2">
                    <p className="text-sm">Min Seats</p>
                    <input
                      className="w-12 text-center bg-white text-black"
                      value={minMaxEnroll.min}
                      onChange={(e) => handleMinChange(Number(e.target.value))}
                      disabled={sessionStatus !== "AVAILABLE"}
                    />
                    <p className="text-sm">Max Seats</p>
                    <input
                      className="w-12 text-center bg-white text-black"
                      value={minMaxEnroll.max}
                      onChange={(e) => handleMaxChange(Number(e.target.value))}
                      disabled={sessionStatus !== "AVAILABLE"}
                    />
                  </div>
                  {sessionStatus === "AVAILABLE" && (
                    <button
                      className="py-1.5 px-5 border border-white"
                      onClick={handleMinMaxSeatsSave}
                      disabled={updateLoading}
                    >
                      {updateLoading ? "Saving..." : "Save"}
                    </button>
                  )}
                </div>
              </td>
            </tr>
          </tfoot> */
}
