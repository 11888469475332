import React, { useState } from "react";
import { FaArrowLeft, FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function BackButton() {
  const [isHover, setIsHover] = useState(false);

  const navigate = useNavigate();

  return (
    <div
      className="flex flex-row items-center justify-start gap-2 cursor-pointer hover:text-[#961e38] transition-all"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => navigate(-1)}
    >
      {isHover ? (
        <FaArrowLeftLong color="#961e38" />
      ) : (
        <FaArrowLeft color="#961e38" />
      )}{" "}
      Back
    </div>
  );
}

export default BackButton;
