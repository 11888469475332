import React, { useState } from "react";

interface PaginationProps {
  totalPages: number;
  total_item_count: number;
  handleChange: ({
    itemsPerPage,
    page,
  }: {
    page: number;
    itemsPerPage: number;
  }) => void;
}

function Pagination({
  totalPages,
  total_item_count,
  handleChange,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState(1); // Current page state

  const [itemsPerPage, setItemsPerPage] = useState(4);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    handleChange({ page, itemsPerPage });
  };
  const handleItemsPerPageChange = (itemsPerPage: number) => {
    setItemsPerPage(itemsPerPage);
    setCurrentPage(1);
    handleChange({ page: 1, itemsPerPage });
  };

  return (
    <div className="flex justify-between items-center w-full gap-10">
      <div className="flex justify-center items-center gap-4 mt-4">
        {/* Previous Button */}
        <button
          className={`px-4 py-2 border rounded ${
            currentPage === 1 ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>

        {/* Dynamic Page Buttons */}
        {Array.from({ length: Math.min(totalPages, 4) }).map((_, index) => {
          const startPage = Math.max(
            1,
            Math.min(currentPage - 1, totalPages - 3)
          );
          const pageNumber = startPage + index;

          return (
            <button
              key={pageNumber}
              className={`px-4 py-2 border rounded ${
                currentPage === pageNumber
                  ? "bg-[#961e38] text-white"
                  : "bg-white text-[#961e38]"
              }`}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}

        {/* Next Button */}
        <button
          className={`px-4 py-2 border rounded ${
            currentPage === totalPages ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <div className="flex justify-center items-center gap-5">
        <p className="text-sm font-semibold">
          Showing {itemsPerPage * (currentPage - 1) + 1} to{" "}
          {Math.min(itemsPerPage * currentPage, total_item_count || 0)} of{" "}
          {total_item_count || 0} results
        </p>
        <div className="flex items-center gap-3">
          <p className="text-sm font-semibold">Items per page:</p>
          <select
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(Number(e.target.value))}
          >
            <option value={4}>4</option>
            <option value={8}>8</option>
            <option value={12}>12</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
