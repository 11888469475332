import { GoHomeFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function HomeButton() {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-row items-center justify-start gap-2 cursor-pointer hover:text-[#961e38] transition-all"
      onClick={() => navigate("/")}
    >
      <GoHomeFill />
      Home
    </div>
  );
}

export default HomeButton;
