import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CourseItem, CourseResponse } from "../../interfaces/courses";
import { getQueryString } from "../../utils/helper";

interface AppliedCourseMetaData {
  total_top_avail: number;
  total_high_avail: number;
}
const initialState = {
  loading: false,
  hrApplicationLoading: false,
  courses: [] as CourseItem[],
  courseResp: {} as CourseResponse,
  appliedHRCourses: [] as any,
  selectedCourseData: {} as CourseItem,
  applidCourseMetaData: {} as AppliedCourseMetaData,
  updateLoading: false,
};

interface QueryProps {
  page_size?: number;
  page?: number;
  sort_attr?: string;
  sort_order?: "asc" | "desc";
  filter_value?: string;
}

interface ApplicationQuery {
  course_id?: string;
  status?: string;
}

export const getCourses = createAsyncThunk(
  "hr/getCourses",
  async (props: QueryProps, { getState, rejectWithValue }) => {
    try {
      const query = getQueryString({
        user_id: sessionStorage.getItem("userId"),
        role: "HR",
        ...props,
      });

      console.log("query", query);

      const url = `${process.env.REACT_APP_BASE_API_URL}/courses${query}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      return response;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const getHRApplications = createAsyncThunk(
  "hr/getHRApplications",
  async (props: ApplicationQuery, { getState, rejectWithValue }) => {
    try {
      let query = getQueryString({
        user_id: sessionStorage.getItem("userId"),
        role: "HR",
        ...props,
      });

      const url = `${process.env.REACT_APP_BASE_API_URL}/applications${query}`;

      const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json());

      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || "Authorization failed"
      );
    }
  }
);

export const updateHRApplicationStatus = createAsyncThunk(
  "learnerApplications/updateLearnerApplicationStatus",
  async (props: any, { dispatch }) => {
    const query = getQueryString({
      user_id: props.user_id,
      role: "HR",
      course_id: props.course_id,
    });

    const requestBody: any = {
      application_status: props.application_status,
    };

    if (props.priority) {
      requestBody.priority = props.priority;
    }

    const url = `${process.env.REACT_APP_BASE_API_URL}/applications/${query}`;

    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    }).then((res) => res.json());

    dispatch(
      getHRApplications({ course_id: props.course_id, status: "HR_APPROVAL" })
    );
    return response.data;
  }
);

const hrSlice = createSlice({
  name: "hr",
  initialState,
  reducers: {
    setSelectedCourseId: (state, action) => {
      state.selectedCourseData = action.payload;
    },
    setEmptyAppliedCourses: (state) => {
      state.appliedHRCourses = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCourses.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCourses.fulfilled, (state, action) => {
        state.loading = false;
        state.courses = action.payload?.data || [];
        state.courseResp = action.payload;
      })
      .addCase(getCourses.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getHRApplications.pending, (state) => {
        state.loading = true;
        state.hrApplicationLoading = true;
      })
      .addCase(getHRApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.hrApplicationLoading = false;
        const { total_high_avail, total_top_avail, applications } =
          action.payload;
        state.appliedHRCourses = applications;
        state.applidCourseMetaData = { total_high_avail, total_top_avail };
      })
      .addCase(getHRApplications.rejected, (state) => {
        state.loading = false;
        state.hrApplicationLoading = false;
      })
      .addCase(updateHRApplicationStatus.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateHRApplicationStatus.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateHRApplicationStatus.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default hrSlice.reducer;
export const { setSelectedCourseId, setEmptyAppliedCourses } = hrSlice.actions;
