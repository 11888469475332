import React from "react";

function Header() {
  const username = sessionStorage.getItem("username");

  const role = sessionStorage.getItem("role");

  return (
    <div className="flex w-full justify-between items-center">
      <div className="flex items-center justify-between gap-5">
        <div className="flex flex-col justify-start items-start w-full">
          <p className="text-2xl font-semibold text-[#ac112d]">Welcome</p>
          <p className="text-lg font-light text-[#ac112d]">
            {username || `Dean JEANE`}
          </p>
        </div>
      </div>
      {role && (
        <p className="text-lg font-semibold text-[#ac112d] flex items-center min-w-[180px]">
          Logged in as {role}
        </p>
      )}
    </div>
  );
}

export default Header;
